// CLOUD SERVICES
export const SWITCH_CLOUD_SERVICE_AIVEN = 'SWITCH_CLOUD_SERVICE_AIVEN'
export const SWITCH_CLOUD_SERVICE_AMAZON_MSK = 'SWITCH_CLOUD_SERVICE_AMAZON_MSK'
export const SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT = 'SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT'
export const SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD = 'SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD'
export const SWITCH_CLOUD_SERVICE_INSTACLUSTR = 'SWITCH_CLOUD_SERVICE_INSTACLUSTR'
// CLOUD FEATURES
export const SWITCH_CLOUD_FEATURE_MIRROR_MAKER = 'SWITCH_CLOUD_FEATURE_MIRROR_MAKER'
export const SWITCH_CLOUD_FEATURE_SCHEMA_REG = 'SWITCH_CLOUD_FEATURE_SCHEMA_REG'
export const SWITCH_CLOUD_FEATURE_KAFKA_CONNECT = 'SWITCH_CLOUD_FEATURE_KAFKA_CONNECT'
export const SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY = 'SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY'
// CLOUD OPTIONS
export const SWITCH_CLOUD_OPTION_AWS = 'SWITCH_CLOUD_OPTION_AWS'
export const SWITCH_CLOUD_OPTION_GCP = 'SWITCH_CLOUD_OPTION_GCP'
export const SWITCH_CLOUD_OPTION_AZURE = 'SWITCH_CLOUD_OPTION_AZURE'
export const SWITCH_CLOUD_OPTION_DIGITAL_OCEAN = 'SWITCH_CLOUD_OPTION_DIGITAL_OCEAN'
export const SWITCH_CLOUD_OPTION_IBM = 'SWITCH_CLOUD_OPTION_IBM'
export const SWITCH_CLOUD_OPTION_UPCLOUD = 'SWITCH_CLOUD_OPTION_UPCLOUD'
export const SWITCH_CLOUD_OPTION_YOUR_CLOUD = 'SWITCH_CLOUD_OPTION_YOUR_CLOUD'
