import {
    SWITCH_CLOUD_SERVICE_AIVEN,
    SWITCH_CLOUD_SERVICE_AMAZON_MSK,
    SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT,
    SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD,
    SWITCH_CLOUD_SERVICE_INSTACLUSTR,
} from '../actions/types'

const initialState = {
    aiven: false,
    amazon_msk: false,
    azure_hd_insight: false,
    confluent_cloud: false,
    instaclustr: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SWITCH_CLOUD_SERVICE_AIVEN:
            return {
                ...state,
                aiven: payload,
            }
        case SWITCH_CLOUD_SERVICE_AMAZON_MSK:
            return {
                ...state,
                amazon_msk: payload,
            }
        case SWITCH_CLOUD_SERVICE_AZURE_HD_INSIGHT:
            return {
                ...state,
                azure_hd_insight: payload,
            }
        case SWITCH_CLOUD_SERVICE_CONFLUENT_CLOUD:
            return {
                ...state,
                confluent_cloud: payload,
            }
        case SWITCH_CLOUD_SERVICE_INSTACLUSTR:
            return {
                ...state,
                instaclustr: payload,
            }
        default:
            return state
    }
}
