import {
    SWITCH_CLOUD_OPTION_AWS,
    SWITCH_CLOUD_OPTION_GCP,
    SWITCH_CLOUD_OPTION_AZURE,
    SWITCH_CLOUD_OPTION_DIGITAL_OCEAN,
    SWITCH_CLOUD_OPTION_IBM,
    SWITCH_CLOUD_OPTION_UPCLOUD,
    SWITCH_CLOUD_OPTION_YOUR_CLOUD,
} from '../actions/types'

const initialState = {
    aws: false,
    google_cloud: false,
    azure: false,
    digital_ocean: false,
    ibm_cloud: false,
    up_cloud: false,
    your_own_cloud: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SWITCH_CLOUD_OPTION_AWS:
            return {
                ...state,
                aws: payload,
            }
        case SWITCH_CLOUD_OPTION_GCP:
            return {
                ...state,
                google_cloud: payload,
            }
        case SWITCH_CLOUD_OPTION_AZURE:
            return {
                ...state,
                azure: payload,
            }
        case SWITCH_CLOUD_OPTION_DIGITAL_OCEAN:
            return {
                ...state,
                digital_ocean: payload,
            }
        case SWITCH_CLOUD_OPTION_IBM:
            return {
                ...state,
                ibm_cloud: payload,
            }
        case SWITCH_CLOUD_OPTION_UPCLOUD:
            return {
                ...state,
                up_cloud: payload,
            }
        case SWITCH_CLOUD_OPTION_YOUR_CLOUD:
            return {
                ...state,
                your_own_cloud: payload,
            }
        default:
            return state
    }
}
