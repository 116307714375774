import { combineReducers } from 'redux'
import cloudServicesReducer from './cloudServicesReducer'
import cloudOptionsReducer from './cloudOptionsReducer'
import cloudFeaturesReducer from './cloudFeaturesReducer'

export default combineReducers({
    cloudServices: cloudServicesReducer,
    cloudOptions: cloudOptionsReducer,
    cloudFeatures: cloudFeaturesReducer,
})
