import {
    SWITCH_CLOUD_FEATURE_MIRROR_MAKER,
    SWITCH_CLOUD_FEATURE_SCHEMA_REG,
    SWITCH_CLOUD_FEATURE_KAFKA_CONNECT,
    SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY,
} from '../actions/types'

const initialState = {
    mirror_maker: false,
    schema_reg: false,
    kafka_connect: false,
    kafka_rest_proxy: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SWITCH_CLOUD_FEATURE_MIRROR_MAKER:
            return {
                ...state,
                mirror_maker: payload,
            }
        case SWITCH_CLOUD_FEATURE_SCHEMA_REG:
            return {
                ...state,
                schema_reg: payload,
            }
        case SWITCH_CLOUD_FEATURE_KAFKA_CONNECT:
            return {
                ...state,
                kafka_connect: payload,
            }
        case SWITCH_CLOUD_FEATURE_KAFKA_REST_PROXY:
            return {
                ...state,
                kafka_rest_proxy: payload,
            }
        default:
            return state
    }
}
